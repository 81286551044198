import React, { PureComponent } from 'react';
import {
  Container,
  Input,
  PrimaryActionButton,
  DangerousActionButton,
  LabelSecondary,
  InputSecondary,
} from './styled';

class ActionInput extends PureComponent {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.secondaryInput = React.createRef();
    this.handlePrimaryAction = this.handlePrimaryAction.bind(this);
    this.handleDangerousAction = this.handleDangerousAction.bind(this);
  }

  handlePrimaryAction() {
    const inputValue = this.input.current.value;
    const { primaryActionHandler, labelSecondary } = this.props;
    const secondaryInputValue = labelSecondary
      ? this.secondaryInput.current.value
      : null;
    if (!inputValue) {
      return;
    }
    if (labelSecondary) {
      primaryActionHandler(inputValue, secondaryInputValue, () => {
        this.input.current.value = '';
        this.secondaryInput.current.value = '';
      });
    } else {
      primaryActionHandler(inputValue, () => {
        this.input.current.value = '';
        this.secondaryInput.current.value = '';
      });
    }
  }

  handleDangerousAction() {
    const { dangerousActionWarning, dangerousActionHandler } = this.props;
    if (confirm(dangerousActionWarning)) {
      dangerousActionHandler();
    }
  }

  render() {
    const {
      width,
      placeholder,
      value,
      primaryActionLabel,
      dangerousActionLabel,
      labelSecondary,
      placeholderSecondary,
      secondaryValue,
    } = this.props;
    return (
      <Container width={width}>
        <Input
          placeholder={placeholder}
          defaultValue={value}
          ref={this.input}
        />
        {labelSecondary && (
          <React.Fragment>
            <LabelSecondary>{labelSecondary}</LabelSecondary>
            <InputSecondary
              placeholder={placeholderSecondary}
              defaultValue={secondaryValue}
              ref={this.secondaryInput}
            />
          </React.Fragment>
        )}
        <PrimaryActionButton type="button" onClick={this.handlePrimaryAction}>
          {primaryActionLabel}
        </PrimaryActionButton>
        {dangerousActionLabel && (
          <DangerousActionButton
            type="button"
            onClick={this.handleDangerousAction}
          >
            {dangerousActionLabel}
          </DangerousActionButton>
        )}
      </Container>
    );
  }
}

export default ActionInput;
