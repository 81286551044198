import data from './data';
import source from './source';
import disease from './disease';
import account from './account';
import * as selectors from './selectors';

const reducers = {
  data,
  source,
  disease,
  account,
};

export default reducers;

export const getTagsGrouppedByType = state =>
  selectors.getTagsGrouppedByType(state.data);

export const getAlphabetizedTagsByActiveType = state =>
  selectors.getAlphabetizedTagsByActiveType(state.data);

export const getAlphabetizedSources = state =>
  selectors.getAlphabetizedSources(state.source);

export const getGrouppedAlphabetizedDiseases = state =>
  selectors.getGrouppedAlphabetizedDiseases(state.disease);

export const getGrouppedFilteredDiseases = (state, filterLetter) =>
  selectors.getGrouppedFilteredDiseases(state.disease, { filterLetter });

// todo: don't pass the whole state
export const getDiseaseByIdWithGrouppedTags = (state, id) =>
  selectors.getDiseaseByIdWithGrouppedTags(state, { id });
