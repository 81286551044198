import React from 'react';
import LoginForm from './loginForm';
import { LoginContainer, H1 } from './styled';

function Login({ login, loginErrors }) {
  return (
    <LoginContainer>
      <H1>Professor of Morphology Admin</H1>
      <LoginForm onSubmit={login} errors={loginErrors} />
    </LoginContainer>
  );
}
export default Login;
