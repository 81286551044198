import React from 'react';
import styled from 'styled-components';
import { NavLink as _NavLink, Link as _Link } from 'react-router-dom';
import { ActionButton, FlatButton } from './button';

const NavLink = styled(_NavLink)`
  color: #000;
  text-decoration: none;
  &.active {
    border-bottom: 2px solid #0872ed;
  }
`;

const Link = styled(_Link)`
  color: #000;
  text-decoration: none;
`;

let _ButtonLink = styled(ActionButton)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-decoration: none;
`;

const ButtonLink = props => <_ButtonLink {...props} as={Link} />;

let _FlatButtonLink = styled(FlatButton)`
  display: inline-flex;
  align-items: center;
  text-decoration: none;
`;

const FlatButtonLink = props => <_FlatButtonLink {...props} as={Link} />;

export default Link;
export { NavLink, ButtonLink, FlatButtonLink };
