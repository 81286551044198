import 'whatwg-fetch';

const getAuthHeader = () => {
  let authToken = localStorage.getItem('authToken');
  return authToken ? { Authorization: 'Bearer ' + authToken } : {};
};
const getOptions = options => ({
  ...options,
  headers: {
    'Access-Control-Allow-Origin': '*',
    credentials: 'include',
    ...options.headers,
    ...getAuthHeader(),
  },
});

export default (url, opts) => {
  const options = getOptions(opts);
  return fetch(url, options)
    .catch(() => Promise.reject(['error']))
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    })
    .then(response => (response ? response.json() : null))
    .then(json => {
      if (json.errors) {
        const error = Array.isArray(json.errors)
          ? json.errors
          : json.errors && json.errors.length > 0
          ? json.errors
          : 'error';
        return Promise.reject(error);
      }
      return Promise.resolve(json.returned);
    });
};
