import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { reducer as toastrReducer } from 'react-redux-toastr';
import reducers from './reducers';

const crashReporter = store => next => action => {
  try {
    return next(action);
  } catch (err) {
    console.error('Caught an exception!', err);
    throw err;
  }
};

const configureStore = history => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const combinedReducers = history =>
    combineReducers({
      router: connectRouter(history),
      toastr: toastrReducer,
      ...reducers,
    });
  const store = createStore(
    combinedReducers(history),
    composeEnhancers(
      applyMiddleware(crashReporter, routerMiddleware(history), thunk)
    )
  );

  return store;
};

export default configureStore;
