import { default as _isLength } from 'validator/lib/isLength';
import isEmpty from 'validator/lib/isEmpty';
import { default as _isEmail } from 'validator/lib/isEmail';
import { default as _isInt } from 'validator/lib/isInt';
import { default as _isPostalCode } from 'validator/lib/isPostalCode';
import { default as _isMobilePhone } from 'validator/lib/isMobilePhone';
import { default as _isAlpha } from 'validator/lib/isAlpha';
import { default as _matches } from 'validator/lib/matches';
import isHexColor from 'validator/lib/isHexColor';

const sanitize = str => (str ? str + '' : '');

export const isLength = (min, max, msg) => val =>
  _isLength(sanitize(val), { min: min, max: max }) ? null : msg;
export const minLength = (length, msg) => val =>
  _isLength(sanitize(val), { min: length }) ? null : msg;
export const maxLength = (length, msg) => val =>
  _isLength(sanitize(val), { min: 0, max: length }) ? null : msg;

export const required = msg => val => (isEmpty(sanitize(val)) ? msg : null);

export const isEmail = msg => val => (_isEmail(sanitize(val)) ? null : msg);

export const isCaMobilePhone = msg => val =>
  _isMobilePhone(sanitize(val), 'en-CA', { strictMode: false }) ? null : msg;

export const isCaPostalCode = msg => val =>
  _isPostalCode(sanitize(val), 'CA') ? null : msg;

export const isInt = (msg, min, max) => val =>
  _isInt(sanitize(val), { min, max }) ? null : msg;

export const isAlpha = (locale, msg) => val =>
  _isAlpha(sanitize(val), locale) ? null : msg;

export const isHex = msg => val =>
  !val || isHexColor(sanitize(val)) ? null : msg;

export const matches = (regexp, msg) => val =>
  _matches(sanitize(val), regexp) ? null : msg;

export const applyValidationRules = (rules, value) => {
  let error;
  for (var i = 0; i < rules.length && !error; i++) {
    error = rules[i](value);
  }
  return error;
};

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);
