import React from 'react';
import Header, { LoginHeader } from '~/components/header';

export function EmptyLayout({ children }) {
  return (
    <>
      <LoginHeader />
      {children}
    </>
  );
}

export function MainLayout(props) {
  const { location, children } = props;
  return (
    <React.Fragment>
      <Header location={location} />
      {children}
    </React.Fragment>
  );
}
