import React from 'react';
import { Header, Nav, NavLink } from './styled';

export default () => (
  <Header>
    <Nav>
      <NavLink to="/sign-up">Register</NavLink>
      <NavLink to="/login">Login</NavLink>
    </Nav>
  </Header>
);
