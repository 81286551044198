import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import {
  updateTag,
  updateTagSuccess,
  updateTagFail,
  deleteTag,
  deleteTagSuccess,
  deleteTagFail,
} from '~/actions/data';
import ActionInput from '~/components/actionInput';

const mapStateToProps = state => {
  const { tagIsProcessing } = state.data;
  return { tagIsProcessing };
};

const mapDispatchToProps = dispatch => ({
  updateTag: (id, name, alias) => {
    dispatch(updateTag(id, name, alias))
      .then(() => dispatch(updateTagSuccess(id, name, alias)))
      .catch(() => {
        toastr.error(
          'Fail',
          'An error has happened. The tag has not been changed.'
        );
        dispatch(updateTagFail());
      });
  },
  deleteTag: id =>
    dispatch(deleteTag(id))
      .then(() => dispatch(deleteTagSuccess(id)))
      .catch(() => {
        toastr.error(
          'Fail',
          'An error has happened. The tag has not been deleted.'
        );
        dispatch(deleteTagFail());
      }),
});

const TagEdit = ({ tag, tagIsProcessing, updateTag, deleteTag }) => (
  <ActionInput
    width={610}
    value={tag.name}
    secondaryValue={tag.alias}
    labelSecondary="Alias"
    placeholderSecondary="(Alias)"
    primaryActionLabel={tagIsProcessing ? '...' : 'Save'}
    primaryActionHandler={(name, alias) =>
      tagIsProcessing ? null : updateTag(tag.id, name, alias)
    }
    dangerousActionLabel={tagIsProcessing ? '...' : 'Del'}
    dangerousActionWarning="Are you sure you want to delete this tag?"
    dangerousActionHandler={name =>
      tagIsProcessing ? null : deleteTag(tag.id, name)
    }
  />
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagEdit);
