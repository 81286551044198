import React from 'react';
import { Route } from 'react-router';
import PrivateRoute from '~/containers/privateRoute';

function AppRoute(props) {
  const {
    component: Component,
    layout: Layout,
    isPublic,
    location,
    ...rest
  } = props;
  if (isPublic) {
    return (
      <Route
        {...rest}
        render={props => (
          <Layout location={location}>
            <Component {...props} />
          </Layout>
        )}
      />
    );
  }
  return <PrivateRoute component={Component} layout={Layout} {...rest} />;
}

export default AppRoute;
