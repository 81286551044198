import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { toastr } from 'react-redux-toastr';
import * as R from 'ramda';
import { fetchData, fetchDataSuccess, fetchDataFail } from '~/actions/data';
import {
  updateDisease,
  updateDiseaseSuccess,
  createDisease,
  createDiseaseSuccess,
  deleteDisease,
  deleteDiseaseSuccess,
} from '~/actions/disease';
import {
  getDiseaseByIdWithGrouppedTags,
  getTagsGrouppedByType,
} from '~/reducers';
import { getAlphabetizedSources } from '~/reducers';
import DiseaseDetails from '~/components/diseaseDetails';

const mapStateToProps = (state, props) => {
  const diseaseIdParam = props.computedMatch.params.id;
  const diseaseId = diseaseIdParam
    ? parseInt(props.computedMatch.params.id, 10)
    : null;
  const { dataIsLoaded, dataIsLoading, dataLoadingError } = state.data;
  const disease = diseaseId
    ? getDiseaseByIdWithGrouppedTags(state, diseaseId)
    : { tags: {}, sources: [] };
  const sources = getAlphabetizedSources(state);
  const grouppedTags = getTagsGrouppedByType(state);
  return {
    dataIsLoaded,
    dataIsLoading,
    dataLoadingError,
    disease,
    sources,
    grouppedTags,
    diseaseKey: diseaseId ? diseaseId : -1,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchData: () => {
    dispatch(fetchData())
      .then(data => dispatch(fetchDataSuccess(data)))
      .catch(() => dispatch(fetchDataFail()));
  },
  updateDisease: formData => {
    const tags = [
      ...(formData.tags.ihc ? formData.tags.ihc : []),
      ...(formData.tags.localization ? formData.tags.localization : []),
      ...(formData.tags.morphology ? formData.tags.morphology : []),
    ];
    const disease = R.omit(['tags'], {
      ...formData,
      tagIds: tags.length > 0 ? R.pluck('id', tags) : [],
    });
    if (disease.id) {
      dispatch(updateDisease(disease))
        .then(editedDate => {
          toastr.success(
            'Success',
            'The disease has been successfully updated.'
          );
          dispatch(updateDiseaseSuccess(disease, editedDate));
        })
        .catch(error => toastr.error('Fail', error));
      return;
    }
    dispatch(createDisease(disease))
      .then(id => {
        toastr.success('Success', 'The disease has been successfully updated.');
        dispatch(createDiseaseSuccess({ ...disease, id }));
        dispatch(push(`/diseases/${id}`));
      })
      .catch(error => toastr.error('Fail', error));
  },
  deleteDisease: id => {
    if (!id || id === -1) {
      return;
    }
    if (!confirm('Are you sure you want to delete this disease?')) {
      return;
    }
    dispatch(deleteDisease(id))
      .then(() => {
        dispatch(push('/diseases'));
        toastr.success('Success', 'The disease has been successfully deleted.');
        dispatch(deleteDiseaseSuccess(id));
      })
      .catch(error => toastr.error('The disease has not been deleted', error));
    return;
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    deleteDisease: () => dispatchProps.deleteDisease(stateProps.diseaseKey),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(DiseaseDetails);
