import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import {
  createSource,
  createSourceSuccess,
  createSourceFail,
} from '~/actions/source';
import ActionInput from '~/components/actionInput';

const mapStateToProps = state => {
  const { sourceIsProcessing } = state.data;
  return { sourceIsProcessing };
};

const mapDispatchToProps = dispatch => ({
  createSource: name =>
    dispatch(createSource(name))
      .then(id => dispatch(createSourceSuccess(id, name)))
      .catch(() => {
        toastr.error(
          'Fail',
          'An error has happened. The source has not been created.'
        );
        dispatch(createSourceFail());
      }),
});

const SourceCreate = ({ sourceIsProcessing, createSource }) => (
  <ActionInput
    width={446}
    placeholder="New source"
    primaryActionLabel={sourceIsProcessing ? '...' : 'Add'}
    primaryActionHandler={name =>
      sourceIsProcessing ? null : createSource(name)
    }
  />
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SourceCreate);
