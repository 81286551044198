import styled from 'styled-components';
import * as styling from '~/consts/styling';

export const Container = styled.div`
  margin: 65px auto 0;
  max-width: 1110px;
`;

export const SourceList = styled.ul`
  margin-top: 50px;
  padding: 0;
  list-style-type: none;
`;

export const SourceItem = styled.li`
  display: flex;
  padding: 12px 0;
  line-height: 26px;
  border-bottom: 1px solid ${styling.borderGray};
  cursor: pointer;
  :last-of-type {
    border-bottom: none;
  }
  :hover {
    background-color: ${styling.tableHover};
  }
`;

export const SourceIdx = styled.div`
  align-self: center;
  padding-right: 10px;
  width: 40px;
  text-align: center;
`;

export const SourceName = styled.div`
  align-self: center;
  flex-grow: 1;
`;

export const SourceEditContainer = styled.div`
  padding: 10px;
  background-color: ${styling.tableHover};
`;
