const NODE_ENV = process.env.NODE_ENV;
let APP_CONFIG;

if (NODE_ENV === 'development') {
  APP_CONFIG = {
    isProd: false,
    apiBaseUrl: 'http://localhost:5000',
  };
} else if (NODE_ENV === 'production') {
  APP_CONFIG = {
    isProd: true,
    apiBaseUrl: 'https://api.pom.etpotato.com',
  };
}

export default APP_CONFIG;
