import React from 'react';
import styled from 'styled-components';
import * as styling from '~/consts/styling';

const Textarea = props => {
  const {
    input,
    placeholder,
    meta: { touched, error },
    ...custom
  } = props;
  const isInvalid = touched && error;
  return (
    <TextareaControl
      isInvalid={isInvalid}
      placeholder={placeholder}
      {...input}
      {...custom}
    />
  );
};

export default Textarea;

const TextareaControl = styled.textarea`
  padding: 14px;
  width: 100%;
  height: 100px;
  font-size: 18px;
  border: ${props =>
    props.isInvalid
      ? `1px solid ${styling.red}`
      : `1px solid ${styling.borderGray}`};
  border-radius: 6px;
  box-sizing: border-box;
`;
