import React, { PureComponent } from 'react';
import {
  Container,
  DiseasesNumber,
  AddBtn,
  Filter,
  Letter,
  DiseasesList,
  TableHeader,
  Group,
  GroupKey,
  DiseasesGroup,
  Disease,
  Name,
  DateUpdated,
} from './styled';

const alphabet = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

class Sources extends PureComponent {
  componentDidMount() {
    const { dataIsLoaded, fetchData } = this.props;
    if (!dataIsLoaded) {
      fetchData();
    }
  }
  render() {
    const {
      dataIsLoaded,
      dataIsLoading,
      dataLoadingError,
      diseases,
      diseasesNumber,
      filterLetter,
      setFilterLetter,
      dropFilterLetter,
      openDiseasDetail,
    } = this.props;
    if (dataIsLoading) {
      return <div>loading</div>;
    }
    if (dataLoadingError) {
      return <div>error</div>;
    }
    const groupKeys = Object.keys(diseases);
    return (
      dataIsLoaded && (
        <Container>
          <div>
            <DiseasesNumber>{diseasesNumber} diseases total</DiseasesNumber>
            <AddBtn to="diseases/new">Add new</AddBtn>
          </div>
          <Filter>
            {alphabet.map(letter => (
              <Letter
                key={letter}
                active={filterLetter === letter}
                onClick={() => setFilterLetter(letter)}
              >
                {letter}
              </Letter>
            ))}
            <Letter onClick={dropFilterLetter} style={{ marginLeft: '20px' }}>
              All
            </Letter>
          </Filter>
          {groupKeys.length === 0 ? (
            <h2>There is no diseases to show</h2>
          ) : (
            <DiseasesList>
              <TableHeader>
                <Name>Name</Name>
                <DateUpdated>Date</DateUpdated>
              </TableHeader>
              <div>
                {groupKeys.map(groupKey => (
                  <Group key={groupKey}>
                    <GroupKey>{groupKey}</GroupKey>
                    <DiseasesGroup>
                      {diseases[groupKey].map(disease => (
                        <Disease
                          key={disease.id}
                          onClick={() => openDiseasDetail(disease.id)}
                        >
                          <Name>{disease.name}</Name>
                          <DateUpdated>{disease.editedDate}</DateUpdated>
                        </Disease>
                      ))}
                    </DiseasesGroup>
                  </Group>
                ))}
              </div>
            </DiseasesList>
          )}
        </Container>
      )
    );
  }
}

export default Sources;
