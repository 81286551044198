import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { register, registerSuccess, registerFail } from '~/actions/account';
import Registration from '~/components/registration';

const mapStateToProps = state => {
  const { registrationErrors } = state.account;
  return {
    registrationErrors,
  };
};

const mapDispatchToProps = dispatch => ({
  register: data => {
    dispatch(register(data))
      .then(authToken => {
        localStorage.setItem('authToken', authToken);
        dispatch(registerSuccess());
        dispatch(push('/'));
      })
      .catch(errors => dispatch(registerFail(errors)));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Registration);
