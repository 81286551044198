import { connect } from 'react-redux';
import { fetchData, fetchDataSuccess, fetchDataFail } from '~/actions/data';
import { selectSource } from '~/actions/source';
import { getAlphabetizedSources } from '~/reducers';
import Sources from '~/components/sources';

const mapStateToProps = state => {
  const { dataIsLoaded, dataIsLoading, dataLoadingError } = state.data;
  const { editedSourceId } = state.source;
  const sources = getAlphabetizedSources(state);
  return {
    dataIsLoaded,
    dataIsLoading,
    dataLoadingError,
    sources,
    editedSourceId,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchData: () => {
    dispatch(fetchData())
      .then(data => dispatch(fetchDataSuccess(data)))
      .catch(() => dispatch(fetchDataFail()));
  },
  selectSource: sourceId => dispatch(selectSource(sourceId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sources);
