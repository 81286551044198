import { loginRequest, registerRequest } from '~/requests/account';

export const LOGIN = '@account/login';
export const LOGIN_SUCCESS = '@account/loginSuccess';
export const LOGIN_FAIL = '@account/loginFail';

export const login = data => dispatch => {
  dispatch({ type: LOGIN });
  return loginRequest(data);
};

export const loginSuccess = () => ({ type: LOGIN_SUCCESS });

export const loginFail = errors => ({ type: LOGIN_FAIL, payload: errors });

export const REGISTER = '@account/register';
export const REGISTER_SUCCESS = '@account/registerSuccess';
export const REGISTER_FAIL = '@account/registerFail';

export const register = data => dispatch => {
  dispatch({ type: REGISTER });
  return registerRequest(data);
};

export const registerSuccess = () => ({ type: REGISTER_SUCCESS });

export const registerFail = errors => ({
  type: REGISTER_FAIL,
  payload: errors,
});
