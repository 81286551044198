import fetch from '~/utils/fetch';
import { DATA_URL, TAG_URL } from '~/consts/urls';

export const getDataRequest = () => fetch(DATA_URL, { method: 'GET' });
export const createTagRequest = (typeId, name, alias) =>
  fetch(TAG_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ typeId, name, alias }),
  });
export const updateTagRequest = (id, name, alias) =>
  fetch(`${TAG_URL}/${id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name, alias }),
  });
export const deleteTagRequest = id =>
  fetch(`${TAG_URL}/${id}`, { method: 'DELETE' });
