import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
} from '~/actions/account';

const defaultState = {
  isAuthenticated: false,
  loginErrors: null,
  registrationErrors: null,
};

export default function account(state = defaultState, action) {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        loginErrors: null,
      };
    }
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
      };
    case LOGIN_FAIL: {
      return {
        ...state,
        loginErrors: action.payload,
      };
    }
    case REGISTER: {
      return {
        ...state,
        registrationErrors: null,
      };
    }
    case REGISTER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
      };
    case REGISTER_FAIL: {
      return {
        ...state,
        registrationErrors: action.payload,
      };
    }
    default:
      return state;
  }
}
