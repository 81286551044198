import React, { PureComponent } from 'react';
import SourceCreate from '~/containers/sourceCreate';
import SourceEdit from '~/containers/sourceEdit';
import {
  Container,
  SourceList,
  SourceItem,
  SourceIdx,
  SourceName,
  SourceEditContainer,
} from './styled';

class Sources extends PureComponent {
  componentDidMount() {
    const { dataIsLoaded, fetchData } = this.props;
    if (!dataIsLoaded) {
      fetchData();
    }
  }
  render() {
    const {
      dataIsLoaded,
      dataIsLoading,
      dataLoadingError,
      sources,
      editedSourceId,
      selectSource,
    } = this.props;
    if (dataIsLoading) {
      return <div>loading</div>;
    }
    if (dataLoadingError) {
      return <div>error</div>;
    }
    return (
      dataIsLoaded && (
        <Container>
          <SourceCreate />
          <SourceList>
            {sources.map((source, idx) => {
              return editedSourceId === source.id ? (
                <SourceEditContainer key={source.id}>
                  <SourceEdit source={source} />
                </SourceEditContainer>
              ) : (
                <SourceItem
                  key={source.id}
                  onClick={() => selectSource(source.id)}
                >
                  <SourceIdx>{idx + 1}</SourceIdx>
                  <SourceName>{source.name}</SourceName>
                </SourceItem>
              );
            })}
          </SourceList>
        </Container>
      )
    );
  }
}

export default Sources;
