import styled from 'styled-components';
import * as styling from '~/consts/styling';
import ButtonBase from '~/components/shared/button';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  height: 50px;
  border: 1px solid ${styling.borderGray};
  border-radius: 6px;
  background-color: white;
  box-sizing: border-box;
`;

export const Input = styled.input`
  flex-grow: 1;
  padding: 14px;
  height: 100%;
  font-size: 16px;
  border: none;
  border-radius: 6px;
`;

export const PrimaryActionButton = styled(ButtonBase)`
  padding: 0 14px 0 14px;
  color: ${styling.blue};
  border-left: 1px solid ${styling.borderGray};
  :hover {
    background-color: ${styling.borderGray};
  }
`;

export const DangerousActionButton = styled(ButtonBase)`
  padding: 0 14px 0 14px;
  color: ${styling.red};
  :hover {
    background-color: ${styling.borderGray};
  }
`;

export const LabelSecondary = styled.div`
  display: flex;
  align-items: center;
  padding: 0 6px;
  height: 100%;
  font-size: 16px;
  background-color: ${styling.borderGray};
`;

export const InputSecondary = styled.input`
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 0 14px;
  height: 100%;
  font-size: 16px;
  border: none;
  border-radius: 6px;
`;
