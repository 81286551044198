import {
  getDataRequest,
  createTagRequest,
  updateTagRequest,
  deleteTagRequest,
} from '~/requests/data';

export const FETCH_DATA = '@data/fetchData';
export const FETCH_DATA_SUCCESS = '@data/fetchDataSuccess';
export const FETCH_DATA_FAIL = '@data/fetchDataFail';

export const fetchData = () => dispatch => {
  dispatch({ type: FETCH_DATA });
  return getDataRequest();
};
export const fetchDataSuccess = data => ({
  type: FETCH_DATA_SUCCESS,
  payload: data,
});
export const fetchDataFail = () => ({ type: FETCH_DATA_FAIL });

export const SET_ACTIVE_TAG_TYPE = '@data/setActiveTagType';

export const setActiveTagType = tagType => ({
  type: SET_ACTIVE_TAG_TYPE,
  payload: tagType,
});

export const SELECT_TAG = '@data/selectTag';

export const selectTag = tagId => ({ type: SELECT_TAG, payload: tagId });

export const CREATE_TAG = '@data/createTag';
export const CREATE_TAG_SUCCESS = '@data/createTagSuccess';
export const CREATE_TAG_FAIL = '@data/createTagFail';

export const createTag = (tagId, name, alias) => dispatch => {
  dispatch({ type: CREATE_TAG });
  return createTagRequest(tagId, name, alias);
};
export const createTagSuccess = (id, typeId, name, alias) => ({
  type: CREATE_TAG_SUCCESS,
  payload: { id, typeId, name, alias },
});
export const createTagFail = () => ({ type: CREATE_TAG_FAIL });

export const UPDATE_TAG = '@data/updateTag';
export const UPDATE_TAG_SUCCESS = '@data/updateTagSuccess';
export const UPDATE_TAG_FAIL = '@data/updateTagFail';

export const updateTag = (id, name, alias) => dispatch => {
  dispatch({ type: UPDATE_TAG });
  return updateTagRequest(id, name, alias);
};
export const updateTagSuccess = (id, name, alias) => ({
  type: UPDATE_TAG_SUCCESS,
  payload: { id, name, alias },
});
export const updateTagFail = () => ({ type: UPDATE_TAG_FAIL });

export const DELETE_TAG = '@data/deleteTag';
export const DELETE_TAG_SUCCESS = '@data/deleteTagSuccess';
export const DELETE_TAG_FAIL = '@data/deleteTagFail';

export const deleteTag = id => dispatch => {
  dispatch({ type: DELETE_TAG });
  return deleteTagRequest(id, name);
};
export const deleteTagSuccess = id => ({
  type: DELETE_TAG_SUCCESS,
  payload: id,
});
export const deleteTagFail = () => ({ type: DELETE_TAG_FAIL });
