import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import {
  updateSource,
  updateSourceSuccess,
  updateSourceFail,
  deleteSource,
  deleteSourceSuccess,
  deleteSourceFail,
} from '~/actions/source';
import ActionInput from '~/components/actionInput';

const mapStateToProps = state => {
  const { sourceIsProcessing } = state.data;
  return { sourceIsProcessing };
};

const mapDispatchToProps = dispatch => ({
  updateSource: (id, name) =>
    dispatch(updateSource(id, name))
      .then(() => dispatch(updateSourceSuccess(id, name)))
      .catch(() => {
        toastr.error(
          'Fail',
          'An error has happened. The source has not been changed.'
        );
        dispatch(updateSourceFail());
      }),
  deleteSource: id =>
    dispatch(deleteSource(id))
      .then(() => dispatch(deleteSourceSuccess(id)))
      .catch(() => {
        toastr.error(
          'Fail',
          'An error has happened. The source has not been deleted.'
        );
        dispatch(deleteSourceFail());
      }),
});

const SourceEdit = props => {
  const { source, sourceIsProcessing, updateSource, deleteSource } = props;
  return (
    <ActionInput
      value={source.name}
      primaryActionLabel={sourceIsProcessing ? '...' : 'Save'}
      primaryActionHandler={name =>
        sourceIsProcessing ? null : updateSource(source.id, name)
      }
      dangerousActionLabel={sourceIsProcessing ? '...' : 'Del'}
      dangerousActionWarning="Are you sure you want to delete this source?"
      dangerousActionHandler={name =>
        sourceIsProcessing ? null : deleteSource(source.id, name)
      }
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SourceEdit);
