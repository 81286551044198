import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { login, loginSuccess, loginFail } from '~/actions/account';
import Login from '~/components/login';

const mapStateToProps = state => {
  const { loginErrors } = state.account;
  return {
    loginErrors,
  };
};

const mapDispatchToProps = dispatch => ({
  login: data => {
    dispatch(login(data))
      .then(authToken => {
        localStorage.setItem('authToken', authToken);
        dispatch(loginSuccess());
        dispatch(push('/'));
      })
      .catch(errors => dispatch(loginFail(errors)));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
