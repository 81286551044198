import * as R from 'ramda';
import {
  FETCH_DATA,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAIL,
  SET_ACTIVE_TAG_TYPE,
  SELECT_TAG,
  CREATE_TAG,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAIL,
  UPDATE_TAG,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_FAIL,
  DELETE_TAG,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAIL,
} from '~/actions/data';
import TagTypes from '~/consts/tagTypes';

const defaultState = {
  dataIsLoaded: false,
  dataIsLoading: false,
  dataLoadingError: false,
  tags: [],
  tumors: [],
  sources: [],
  activeTagType: TagTypes.Localization,
  editedTagId: null,
  tagIsProcessing: false,
};

export default function data(state = defaultState, action) {
  switch (action.type) {
    case FETCH_DATA: {
      return {
        ...defaultState,
        dataIsLoaded: false,
        dataIsLoading: true,
        dataLoadingError: false,
      };
    }
    case FETCH_DATA_SUCCESS: {
      return {
        ...defaultState,
        dataIsLoaded: true,
        dataIsLoading: false,
        dataLoadingError: false,
        ...action.payload,
      };
    }
    case FETCH_DATA_FAIL: {
      return {
        ...defaultState,
        dataIsLoaded: false,
        dataIsLoading: false,
        dataLoadingError: true,
      };
    }
    case SET_ACTIVE_TAG_TYPE: {
      return {
        ...state,
        activeTagType: action.payload,
      };
    }
    case SELECT_TAG: {
      return {
        ...state,
        editedTagId: action.payload,
      };
    }
    case CREATE_TAG: {
      return {
        ...state,
        tagIsProcessing: true,
      };
    }
    case CREATE_TAG_SUCCESS: {
      const tag = { ...action.payload };
      const tags = R.append(tag, state.tags);
      return {
        ...state,
        tags,
        tagIsProcessing: false,
      };
    }
    case CREATE_TAG_FAIL: {
      return {
        ...state,
        tagIsProcessing: false,
      };
    }
    case UPDATE_TAG: {
      return {
        ...state,
        tagIsProcessing: true,
      };
    }
    case UPDATE_TAG_SUCCESS: {
      const { id, name, alias } = action.payload;
      let { tags } = state;
      const tagIdx = R.findIndex(R.propEq('id', id), tags);
      tags = R.adjust(tagIdx, R.assoc('name', name), tags);
      tags = R.adjust(tagIdx, R.assoc('alias', alias), tags);
      return {
        ...state,
        tags,
        editedTagId: null,
        tagIsProcessing: false,
      };
    }
    case UPDATE_TAG_FAIL: {
      return {
        ...state,
        tagIsProcessing: false,
      };
    }
    case DELETE_TAG: {
      return {
        ...state,
        tagIsProcessing: true,
      };
    }
    case DELETE_TAG_SUCCESS: {
      let { tags } = state;
      const tagIdx = R.findIndex(R.propEq('id', action.payload), tags);
      tags = R.remove(tagIdx, 1, tags);
      return {
        ...state,
        tags,
        editedTagId: null,
        tagIsProcessing: false,
      };
    }
    case DELETE_TAG_FAIL: {
      return {
        ...state,
        tagIsProcessing: false,
      };
    }
    default:
      return state;
  }
}
