import React, { useEffect } from 'react';
import * as R from 'ramda';
import TagTypes from '~/consts/tagTypes';
import NavItem from '~/components/navItem';
import TagCreate from '~/containers/tagCreate';
import TagEdit from '~/containers/tagEdit';
import {
  Container,
  Filter,
  TagsContainer,
  TagsBlock,
  Letter,
  TagList,
  Tag,
} from './styled';

function Tags(props) {
  useEffect(() => {
    const { fetchData } = props;
    fetchData();
  }, []);
  const {
    dataIsLoaded,
    dataIsLoading,
    dataLoadingError,
    tagsGrouppedByType,
    alphabetizedTagsByActiveType,
    activeTagType,
    editedTagId,
    setActiveTagType,
    selectTag,
  } = props;

  if (dataIsLoading) {
    return <div>loading</div>;
  }
  if (dataLoadingError) {
    return <div>error</div>;
  }
  return (
    dataIsLoaded && (
      <Container>
        <TagCreate tagType={activeTagType} />
        <Filter>
          <NavItem
            marginLeft="25"
            active={activeTagType === TagTypes.Localization}
            onClick={() => setActiveTagType(TagTypes.Localization)}
          >
            Localization ({tagsGrouppedByType['1'].length})
          </NavItem>
          <NavItem
            marginLeft="25"
            active={activeTagType === TagTypes.Morphology}
            onClick={() => setActiveTagType(TagTypes.Morphology)}
          >
            Morphology ({tagsGrouppedByType['2'].length})
          </NavItem>
          <NavItem
            marginLeft="25"
            active={activeTagType === TagTypes.IHC}
            onClick={() => setActiveTagType(TagTypes.IHC)}
          >
            IHC ({tagsGrouppedByType['3'].length})
          </NavItem>
        </Filter>
        <TagsContainer>
          {R.map(letter => (
            <TagsBlock key={letter}>
              <Letter>{letter}</Letter>
              <TagList>
                {R.map(tag => {
                  return editedTagId === tag.id ? (
                    <TagEdit key={tag.id} tag={tag} />
                  ) : (
                    <Tag key={tag.id} onClick={() => selectTag(tag.id)}>
                      {tag.name}
                      {tag.alias && <i> ({tag.alias})</i>}
                    </Tag>
                  );
                })(alphabetizedTagsByActiveType[letter])}
              </TagList>
            </TagsBlock>
          ))(Object.keys(alphabetizedTagsByActiveType))}
        </TagsContainer>
      </Container>
    )
  );
}

export default Tags;
