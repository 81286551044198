import styled from 'styled-components';
import { NavLink as NavLinkBase } from '~/components/shared/link';

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding-top: 35px;
  max-width: 1108px;
  height: 60px;
`;

export const Brand = styled.div`
  display: flex;
  align-items: center;
`;

export const Logo = styled.img`
  width: 60px;
  height: 60px;
`;

export const TitleLink = styled(NavLinkBase)`
  margin-left: 19px;
  height: 24px;
  font-size: 18px;
  line-height: 22px;
  box-sizing: border-box;
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
`;

export const NavLink = styled(NavLinkBase)`
  margin-left: 35px;
  height: 22px;
  font-size: 16px;
  box-sizing: border-box;
`;
