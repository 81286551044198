import React, { PureComponent } from 'react';
import { Container } from './styled';
import DiseaseDetailsForm from './diseaseDetailsForm';

class DiseaseDetails extends PureComponent {
  componentDidMount() {
    const { dataIsLoaded, fetchData } = this.props;
    if (!dataIsLoaded) {
      fetchData();
    }
  }
  render() {
    const {
      dataIsLoaded,
      dataIsLoading,
      dataLoadingError,
      diseaseKey,
      disease,
      sources,
      grouppedTags,
      updateDisease,
      deleteDisease,
    } = this.props;
    if (dataIsLoading) {
      return <div>loading</div>;
    }
    if (dataLoadingError) {
      return <div>error</div>;
    }
    return (
      dataIsLoaded && (
        <Container>
          <DiseaseDetailsForm
            diseaseKey={diseaseKey}
            disease={disease}
            sourcesList={sources}
            grouppedTags={grouppedTags}
            deleteDisease={deleteDisease}
            onSubmit={updateDisease}
          />
        </Container>
      )
    );
  }
}

export default DiseaseDetails;
