export const backgroundColor = '#fafbfc';
export const green = '#20a936';
export const red = '#e22424';
export const textBlackBold = '#303030';
export const textBlack = '#151515';
export const textGray = '#707070';
export const blue = '#0872ed';
export const borderGray = '#dae2ea';
export const separatorGray = '#e5e5e5';
export const tableHover = '#F5F7F9';

export const shadow = '0px 2px 4px rgba(0,0,0,.2)';
