import {
  createSourceRequest,
  updateSourceRequest,
  deleteSourceRequest,
} from '~/requests/source';

export const SELECT_SOURCE = '@data/selectSource';

export const selectSource = tagId => ({ type: SELECT_SOURCE, payload: tagId });

export const CREATE_SOURCE = '@data/createSource';
export const CREATE_SOURCE_SUCCESS = '@data/createSourceSuccess';
export const CREATE_SOURCE_FAIL = '@data/createSourceFail';

export const createSource = (tagId, name) => dispatch => {
  dispatch({ type: CREATE_SOURCE });
  return createSourceRequest(tagId, name);
};
export const createSourceSuccess = (id, name) => ({
  type: CREATE_SOURCE_SUCCESS,
  payload: { id, name },
});
export const createSourceFail = () => ({ type: CREATE_SOURCE_FAIL });

export const UPDATE_SOURCE = '@data/updateSource';
export const UPDATE_SOURCE_SUCCESS = '@data/updateSourceSuccess';
export const UPDATE_SOURCE_FAIL = '@data/updateSourceFail';

export const updateSource = (id, name) => dispatch => {
  dispatch({ type: UPDATE_SOURCE });
  return updateSourceRequest(id, name);
};
export const updateSourceSuccess = (id, name) => ({
  type: UPDATE_SOURCE_SUCCESS,
  payload: { id, name },
});
export const updateSourceFail = () => ({ type: UPDATE_SOURCE_FAIL });

export const DELETE_SOURCE = '@data/deleteSource';
export const DELETE_SOURCE_SUCCESS = '@data/deleteSourceSuccess';
export const DELETE_SOURCE_FAIL = '@data/deleteSourceFail';

export const deleteSource = id => dispatch => {
  dispatch({ type: DELETE_SOURCE });
  return deleteSourceRequest(id, name);
};
export const deleteSourceSuccess = id => ({
  type: DELETE_SOURCE_SUCCESS,
  payload: id,
});
export const deleteSourceFail = () => ({ type: DELETE_SOURCE_FAIL });
