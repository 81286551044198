import * as R from 'ramda';
import { FETCH_DATA_SUCCESS } from '~/actions/data';
import {
  SELECT_SOURCE,
  CREATE_SOURCE,
  CREATE_SOURCE_SUCCESS,
  CREATE_SOURCE_FAIL,
  UPDATE_SOURCE,
  UPDATE_SOURCE_SUCCESS,
  UPDATE_SOURCE_FAIL,
  DELETE_SOURCE,
  DELETE_SOURCE_SUCCESS,
  DELETE_SOURCE_FAIL,
} from '~/actions/source';

const defaultState = {
  dataIsLoaded: false,
  dataIsLoading: false,
  dataLoadingError: false,
  sources: [],
  editedSourceId: null,
  sourceIsProcessing: false,
};

export default function source(state = defaultState, action) {
  switch (action.type) {
    case FETCH_DATA_SUCCESS: {
      return {
        ...defaultState,
        dataIsLoaded: true,
        dataIsLoading: false,
        dataLoadingError: false,
        sources: [...action.payload.sources],
      };
    }
    case SELECT_SOURCE: {
      return {
        ...state,
        editedSourceId: action.payload,
      };
    }
    case CREATE_SOURCE: {
      return {
        ...state,
        sourceIsProcessing: true,
      };
    }
    case CREATE_SOURCE_SUCCESS: {
      const source = { ...action.payload };
      const sources = R.append(source, state.sources);
      return {
        ...state,
        sources,
        sourceIsProcessing: false,
      };
    }
    case CREATE_SOURCE_FAIL: {
      return {
        ...state,
        sourceIsProcessing: false,
      };
    }
    case UPDATE_SOURCE: {
      return {
        ...state,
        sourceIsProcessing: true,
      };
    }
    case UPDATE_SOURCE_SUCCESS: {
      const { id, name } = action.payload;
      let { sources } = state;
      const sourceIdx = R.findIndex(R.propEq('id', id), sources);
      sources = R.adjust(sourceIdx, R.assoc('name', name), sources);
      return {
        ...state,
        sources,
        editedSourceId: null,
        sourceIsProcessing: false,
      };
    }
    case UPDATE_SOURCE_FAIL: {
      return {
        ...state,
        sourceIsProcessing: false,
      };
    }
    case DELETE_SOURCE: {
      return {
        ...state,
        sourceIsProcessing: true,
      };
    }
    case DELETE_SOURCE_SUCCESS: {
      let { sources } = state;
      const sourceIdx = R.findIndex(R.propEq('id', action.payload), sources);
      sources = R.remove(sourceIdx, 1, sources);
      return {
        ...state,
        sources,
        editedSourceId: null,
        sourceIsProcessing: false,
      };
    }
    case DELETE_SOURCE_FAIL: {
      return {
        ...state,
        sourceIsProcessing: false,
      };
    }
    default:
      return state;
  }
}
