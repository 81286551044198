import { connect } from 'react-redux';
import {
  fetchData,
  fetchDataSuccess,
  fetchDataFail,
  setActiveTagType,
  selectTag,
} from '~/actions/data';
import {
  getTagsGrouppedByType,
  getAlphabetizedTagsByActiveType,
} from '~/reducers';
import Tags from '~/components/tags';

const mapStateToProps = state => {
  const {
    dataIsLoaded,
    dataIsLoading,
    dataLoadingError,
    activeTagType,
    editedTagId,
  } = state.data;
  const tagsGrouppedByType = getTagsGrouppedByType(state);
  const alphabetizedTagsByActiveType = getAlphabetizedTagsByActiveType(state);
  return {
    dataIsLoaded,
    dataIsLoading,
    dataLoadingError,
    tagsGrouppedByType,
    activeTagType,
    editedTagId,
    alphabetizedTagsByActiveType,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchData: () => {
    dispatch(fetchData())
      .then(data => dispatch(fetchDataSuccess(data)))
      .catch(() => dispatch(fetchDataFail()));
  },
  setActiveTagType: tagType => dispatch(setActiveTagType(tagType)),
  selectTag: tagId => dispatch(selectTag(tagId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tags);
