import React from 'react';
import RegistrationForm from './registrationForm';
import { LoginContainer, H1 } from './styled';

function Registration({ register, registrationErrors }) {
  return (
    <LoginContainer>
      <H1>Professor of Morphology Admin</H1>
      <RegistrationForm onSubmit={register} errors={registrationErrors} />
    </LoginContainer>
  );
}
export default Registration;
