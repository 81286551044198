import styled from 'styled-components';

export const Container = styled.div`
  margin: 65px auto 0;
  max-width: 1110px;
`;

export const Filter = styled.div`
  display: flex;
  margin-top: 52px;
`;

export const TagsContainer = styled.div`
  margin-top: 48px;
`;

export const TagsBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
  :first-of-type {
    margin-top: 0;
  }
`;

export const Letter = styled.div`
  width: 95px;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const TagList = styled.ul`
  flex-grow: 1;
  margin: 0;
`;

export const Tag = styled.li`
  list-style-type: none;
  line-height: 28px;
  cursor: pointer;
`;
