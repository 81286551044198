import React, { useRef, useMemo } from 'react';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import TagCreate from '~/containers/tagCreate';
import { ActionButton, FlatButton } from '~/components/shared/button';
import { FlatButtonLink } from '~/components/shared/link';
import { Text, ReactSelect, Textarea } from '~/components/ui';
import {
  TopControls,
  ControlGroup,
  ButtonContainer,
  Label,
  RadioLabel,
  FormGroup,
  ColHalf,
  RadioGroup,
  NoteContainer,
  SelectSource,
  PageSourceContainer,
  PageSource,
  AddSourceButton,
  SourceList,
  SourceRow,
  SourceRowNumber,
  SourceRowTitle,
  SourceRowPage,
  SourceRowButton,
} from './styled';
import { required, applyValidationRules } from '~/utils/validation';
import TagTypes from '~/consts/tagTypes';

const containerHeight = 'auto';
const tagHeight = 34;

const nameRequired = [required('Disease Title is required')];
const iCDOCodeRequired = [required('ICD-O code is required')];

function validation(values) {
  let errors = {};
  const nameError = applyValidationRules(nameRequired, values.name);
  const iCDOCodeError = applyValidationRules(iCDOCodeRequired, values.icdoCode);
  if (nameError) {
    errors.name = nameError;
  }
  if (iCDOCodeError) {
    errors.icdoCode = iCDOCodeError;
  }
  if (!values.sources || values.sources.length === 0) {
    errors.sources1 = 'There must be at least one source specified';
  }
  if (values.sources && values.sources.filter(s => !s.pages).length > 0) {
    errors.sources1 = 'You must specify pages for all the sources';
  }
  return errors;
}

const addTag = tagNamePath => ([tag], state, utils) => {
  utils.changeValue(state, tagNamePath, tags => {
    if (tags) {
      tags.push(tag);
    } else {
      tags = [tag];
    }
    return tags;
  });
  utils.changeValue(state, 'tagIds', tagIds => {
    if (tagIds) {
      tagIds.push(tag.id);
    } else {
      tagIds = [tag.id];
    }
    return tagIds;
  });
};

const addLocalizationTag = addTag('tags.localization');
const addMorphologyTag = addTag('tags.morphology');
const addIHCTag = addTag('tags.ihc');

const DiseaseDetailsForm = props => {
  const {
    diseaseKey,
    disease,
    sourcesList,
    grouppedTags,
    deleteDisease,
    onSubmit,
  } = props;
  const sourceEl = useRef(null);
  const pagesEl = useRef(null);
  const initialDiseaseValue = useMemo(() => {
    return disease;
  }, [diseaseKey]);

  return (
    <Form
      initialValues={initialDiseaseValue}
      onSubmit={onSubmit}
      validate={validation}
      mutators={{
        addLocalizationTag,
        addMorphologyTag,
        addIHCTag,
        ...arrayMutators,
      }}
      render={({
        handleSubmit,
        form: {
          mutators: { push, addLocalizationTag, addMorphologyTag, addIHCTag },
        },
        values,
        errors,
      }) => {
        const prevalenceDisabled = !values.sex && !values.age;
        return (
          <form onSubmit={handleSubmit}>
            <TopControls>
              <ControlGroup>
                <ButtonContainer>
                  <ActionButton type="submit">Save</ActionButton>
                </ButtonContainer>
                <ButtonContainer>
                  <FlatButtonLink to="/diseases">Cancel Editing</FlatButtonLink>
                </ButtonContainer>
              </ControlGroup>
              {diseaseKey !== -1 && (
                <ControlGroup alignRight>
                  <FlatButton
                    type="button"
                    kind="danger"
                    onClick={deleteDisease}
                  >
                    Delete
                  </FlatButton>
                </ControlGroup>
              )}
            </TopControls>
            <FormGroup>
              <Label htmlFor="Name">Disease Title</Label>
              <Field
                name="name"
                id="Name"
                placeholder="Enter Disease Title"
                component={Text}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="IcdoCode">ICD-O code</Label>
              <Field
                name="icdoCode"
                id="IcdoCode"
                placeholder="ICD-O code"
                component={Text}
              />
            </FormGroup>
            <FormGroup inline>
              <RadioGroup>
                <div>
                  <Field
                    component="input"
                    type="radio"
                    name="age"
                    id="AgeNone"
                    value=""
                  />
                  <label htmlFor="AgeNone">&nbsp;None</label>
                </div>
                <div>
                  <Field
                    component="input"
                    type="radio"
                    name="age"
                    id="AgeChildren"
                    value="Children"
                  />
                  <label htmlFor="AgeChildren">&nbsp;Children</label>
                </div>
                <div>
                  <Field
                    component="input"
                    type="radio"
                    name="age"
                    id="AgeAdults"
                    value="Adults"
                  />
                  <label htmlFor="AgeAdults">&nbsp;Adults</label>
                </div>
              </RadioGroup>
              <RadioGroup>
                <div>
                  <Field
                    component="input"
                    type="radio"
                    name="sex"
                    id="SexNone"
                    value=""
                  />
                  <label htmlFor="SexNone">&nbsp;None</label>
                </div>
                <div>
                  <Field
                    component="input"
                    type="radio"
                    name="sex"
                    id="SexFemale"
                    value="Female"
                  />
                  <label htmlFor="SexFemale">&nbsp;Female</label>
                </div>
                <div>
                  <Field
                    component="input"
                    type="radio"
                    name="sex"
                    id="SexMale"
                    value="Male"
                  />
                  <label htmlFor="SexMale">&nbsp;Male</label>
                </div>
              </RadioGroup>
              <RadioGroup>
                <div>
                  <Field
                    component="input"
                    type="radio"
                    name="prevalence"
                    id="Predominantly"
                    value="true"
                    disabled={prevalenceDisabled}
                  />
                  <RadioLabel
                    htmlFor="Predominantly"
                    disabled={prevalenceDisabled}
                  >
                    &nbsp;Predominantly
                  </RadioLabel>
                </div>
                <div>
                  <Field
                    component="input"
                    type="radio"
                    name="prevalence"
                    id="Only"
                    value="Female"
                    disabled={prevalenceDisabled}
                  />
                  <RadioLabel htmlFor="Only" disabled={prevalenceDisabled}>
                    &nbsp;Only
                  </RadioLabel>
                </div>
              </RadioGroup>
            </FormGroup>
            <Label htmlFor="Localization">Localization</Label>
            <FormGroup inline>
              <ColHalf>
                <Field
                  key={values.tagIds ? values.tagIds.length : 0}
                  name="tags.localization"
                  id="Localization"
                  isMulti
                  options={grouppedTags[TagTypes.Localization]}
                  getOptionValue={({ id }) => id}
                  getOptionLabel={({ name }) => name}
                  placeholder="Enter the research results"
                  component={ReactSelect}
                  containerHeight={containerHeight}
                  tagHeight={tagHeight}
                />
              </ColHalf>
              <ColHalf>
                <TagCreate
                  tagType={TagTypes.Localization}
                  onSuccess={addLocalizationTag}
                />
              </ColHalf>
            </FormGroup>
            <Label htmlFor="Morphology">Morphology</Label>
            <FormGroup inline>
              <ColHalf>
                <Field
                  key={values.tagIds ? values.tagIds.length : 0}
                  name="tags.morphology"
                  id="Morphology"
                  isMulti
                  options={grouppedTags[TagTypes.Morphology]}
                  getOptionValue={({ id }) => id}
                  getOptionLabel={({ name }) => name}
                  placeholder="Enter the research results"
                  component={ReactSelect}
                  containerHeight={containerHeight}
                  tagHeight={tagHeight}
                />
              </ColHalf>
              <ColHalf>
                <TagCreate
                  tagType={TagTypes.Morphology}
                  onSuccess={addMorphologyTag}
                />
              </ColHalf>
            </FormGroup>
            <Label htmlFor="IHC">IHC</Label>
            <FormGroup inline>
              <ColHalf>
                <Field
                  key={values.tagIds ? values.tagIds.length : 0}
                  name="tags.ihc"
                  id="IHC"
                  isMulti
                  options={grouppedTags[TagTypes.IHC]}
                  getOptionValue={({ id }) => id}
                  getOptionLabel={({ name }) => name}
                  placeholder="Enter the research results"
                  component={ReactSelect}
                  containerHeight={containerHeight}
                  tagHeight={tagHeight}
                />
              </ColHalf>
              <ColHalf>
                <TagCreate tagType={TagTypes.IHC} onSuccess={addIHCTag} />
              </ColHalf>
            </FormGroup>
            <NoteContainer>
              <Label htmlFor="Note">Notes</Label>
              <Field
                name="note"
                id="Note"
                placeholder="Note"
                component={Textarea}
              />
            </NoteContainer>
            <FormGroup inline>
              <div>
                <Label htmlFor="Source">Source</Label>
                <SelectSource ref={sourceEl}>
                  <option value="" disabled selected>
                    C'mon, choose one
                  </option>
                  {sourcesList.map(source => (
                    <option key={source.id} value={source.id}>
                      {source.name}
                    </option>
                  ))}
                </SelectSource>
              </div>
              <PageSourceContainer>
                <Label htmlFor="Page">Page</Label>
                <PageSource
                  type="text"
                  name="page"
                  id="Page"
                  ref={pagesEl}
                  placeholder="142,142"
                />
              </PageSourceContainer>
              <AddSourceButton
                type="button"
                onClick={() => {
                  if (sourceEl.current.value === '') {
                    alert('Select a source.');
                    return;
                  }
                  const alreadyContains =
                    values.sources.filter(
                      s =>
                        parseInt(s.id, 10) ===
                        parseInt(sourceEl.current.value, 10)
                    ).length > 0;
                  if (alreadyContains) {
                    alert('You have already added this source.');
                    return;
                  }
                  if (!pagesEl.current.value) {
                    alert('You must specify pages for the source.');
                    return;
                  }
                  push('sources', {
                    id: parseInt(sourceEl.current.value, 10),
                    pages: pagesEl.current.value,
                  });
                  sourceEl.current.value = '';
                }}
              >
                Add source
              </AddSourceButton>
            </FormGroup>
            {errors.sources1 && (
              <div style={{ color: '#e22424' }}>{errors.sources1}</div>
            )}
            <FieldArray name="sources">
              {({ fields }) => (
                <SourceList>
                  {fields.map((name, index) => (
                    <SourceRow key={name}>
                      <SourceRowNumber>{index + 1}.</SourceRowNumber>
                      <SourceRowTitle>
                        {
                          sourcesList.filter(s => {
                            try {
                              return (
                                s.id === parseInt(values.sources[index].id, 10)
                              );
                            } catch (ex) {
                              return [{ name: 'tmp' }];
                            }
                          })[0].name
                        }
                      </SourceRowTitle>
                      <SourceRowPage>
                        <Field name={`${name}.pages`} component={Text} />
                      </SourceRowPage>
                      <SourceRowButton>
                        <FlatButton
                          type="button"
                          onClick={() => fields.remove(index)}
                        >
                          ❌
                        </FlatButton>
                      </SourceRowButton>
                    </SourceRow>
                  ))}
                </SourceList>
              )}
            </FieldArray>
            <br />
            <FormGroup>
              <ActionButton type="submit">Save</ActionButton>
            </FormGroup>
          </form>
        );
      }}
    />
  );
};

export default DiseaseDetailsForm;
