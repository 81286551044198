import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import ReduxToastr from 'react-redux-toastr';
import configureStore from './configureStore';
import { AppContainer } from 'react-hot-loader';
import { loginSuccess } from '~/actions/account';
import App from '~/components/app';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import '~/global.css';

const history = createBrowserHistory();
const store = configureStore(history);

const isLoggedIn = localStorage.getItem('authToken');
if (isLoggedIn) {
  store.dispatch(loginSuccess());
}

ReactDOM.render(
  <AppContainer>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <React.Fragment>
          <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
          />
          <App />
        </React.Fragment>
      </ConnectedRouter>
    </Provider>
  </AppContainer>,
  document.getElementById('root')
);
