import React from 'react';
import { Form, Field } from 'react-final-form';
import { ActionButton } from '~/components/shared/button';
import { Text } from '~/components/ui';
import { Label, FormGroup, ErrorList, Error } from './styled';
import { required, isEmail, composeValidators } from '~/utils/validation';

const userNameValidation = required('User Name is required');
const emailValidation = composeValidators(
  required('Email is required'),
  isEmail('Email is incorrect')
);
const passwordValidation = composeValidators(required('Password is required'));
const repeatPasswordValidation = composeValidators(required('Repeat Password'));

function validate(values) {
  let errors = {};
  const userNameErrors = userNameValidation(values.userName);
  if (userNameErrors) {
    errors.userName = userNameErrors;
  }
  const emailErrors = emailValidation(values.email);
  if (emailErrors) {
    errors.email = emailErrors;
  }
  const passwordErrors = passwordValidation(values.password);
  if (passwordErrors) {
    errors.password = passwordErrors;
  }
  const repeatPasswordErrors = repeatPasswordValidation(values.repeatPassword);
  if (repeatPasswordErrors) {
    errors.repeatPassword = repeatPasswordErrors;
  } else if (values.password !== values.repeatPassword) {
    errors.repeatPassword = "Passwords don't match";
  }
  return errors;
}

function RegistrationForm(props) {
  const { onSubmit, errors } = props;
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <Label htmlFor="UserName">User Name</Label>
              <Field
                type="text"
                name="userName"
                id="UserName"
                component={Text}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="Email">Email</Label>
              <Field type="email" name="email" id="Email" component={Text} />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="Password">Password</Label>
              <Field
                type="password"
                name="password"
                id="Password"
                component={Text}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="RepeatPassword">Repeat Password</Label>
              <Field
                type="password"
                name="repeatPassword"
                id="RepeatPassword"
                component={Text}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="Code">Security Code</Label>
              <Field type="password" name="code" id="Code" component={Text} />
            </FormGroup>
            {errors && errors.length > 0 && (
              <ErrorList>
                {errors.map((e, i) => (
                  <Error key={i}>{e}</Error>
                ))}
              </ErrorList>
            )}
            <FormGroup>
              <ActionButton type="submit">Sign Up</ActionButton>
            </FormGroup>
          </form>
        );
      }}
    />
  );
}

export default RegistrationForm;
