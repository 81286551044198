import React from 'react';
import styled from 'styled-components';
import * as styling from '~/consts/styling';

const Select = ({ input, meta: { touched, error }, ...custom }) => {
  const isInvalid = touched && error;
  return <_Select isInvalid={isInvalid} {...input} {...custom} />;
};

export default Select;

const _Select = styled.select`
  padding: 14px;
  width: 100%;
  height: 50px;
  font-size: 18px;
  border: ${props =>
    props.isInvalid
      ? `1px solid ${styling.red}`
      : `1px solid ${styling.borderGray}`};
  border-radius: 6px;
  box-sizing: border-box;
`;
