import * as R from 'ramda';
import { createSelector } from 'reselect';
import TagTypes from '~/consts/tagTypes';

const getTags = state => state.tags;
export const getTagsGrouppedByType = createSelector(
  [getTags],
  tags => {
    return R.groupBy(R.prop('typeId'), tags);
  }
);

const getActiveTagType = state => state.activeTagType;
export const getAlphabetizedTagsByActiveType = createSelector(
  [getTagsGrouppedByType, getActiveTagType],
  (tagsGrouppedByType, activeTagType) => {
    if (R.isEmpty(tagsGrouppedByType)) {
      return [];
    }
    const tagsByActiveType = tagsGrouppedByType[activeTagType];
    const alphabetizedTagsByActiveType = R.pipe(
      R.sortWith([R.ascend(tag => tag.name.toLowerCase())]),
      R.groupBy(tag => tag.name[0].toLowerCase())
    )(tagsByActiveType);
    return alphabetizedTagsByActiveType;
  }
);

const getSources = state => state.sources;
export const getAlphabetizedSources = createSelector(
  [getSources],
  sources => {
    if (R.isEmpty(sources)) {
      return [];
    }
    return R.sortWith([R.ascend(source => source.name.toLowerCase())], sources);
  }
);

const getDiseases = state => state.diseases;
export const getGrouppedAlphabetizedDiseases = createSelector(
  [getDiseases],
  diseases => {
    if (R.isEmpty(diseases)) {
      return {};
    }
    return R.pipe(
      R.sortWith([R.ascend(disease => disease.name.toLowerCase())]),
      R.groupBy(source =>
        source.name.length < 2
          ? source.name
          : R.take(2, source.name.toLowerCase())
      )
    )(diseases);
  }
);

const getFilterLetter = (state, props) => props.filterLetter;
export const getGrouppedFilteredDiseases = createSelector(
  [getGrouppedAlphabetizedDiseases, getFilterLetter],
  (diseases, filterLetter) => {
    if (R.isEmpty(diseases)) {
      return {};
    }
    if (!filterLetter) {
      return diseases;
    }
    return R.pipe(
      R.toPairs,
      R.filter(pair => R.startsWith(filterLetter, pair[0])),
      R.fromPairs
    )(diseases);
  }
);

const getTagsFromFullState = state => state.data.tags;
const getDiseasesFromFullState = state => state.disease.diseases;
const getDiseaseId = (state, props) => props.id;
export const getDiseaseByIdWithGrouppedTags = createSelector(
  [getDiseasesFromFullState, getTagsFromFullState, getDiseaseId],
  (diseases, tags, id) => {
    if (R.isEmpty(diseases)) {
      return {};
    }
    const disease = R.find(R.propEq('id', id), diseases);
    const boundGrouppedTags = R.pipe(
      R.filter(R.propSatisfies(id => R.includes(id, disease.tagIds), 'id')),
      R.groupBy(R.prop('typeId'))
    )(tags);
    const diseaseByIdWithGrouppedTags = {
      ...disease,
      tags: {
        localization: boundGrouppedTags[TagTypes.Localization],
        morphology: boundGrouppedTags[TagTypes.Morphology],
        ihc: boundGrouppedTags[TagTypes.IHC],
      },
    };
    return diseaseByIdWithGrouppedTags;
  }
);
