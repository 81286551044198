import { connect } from 'react-redux';
import React from 'react';
import { Route, Redirect } from 'react-router';

function PrivateRouteContainer(props) {
  const {
    isAuthenticated,
    component: Component,
    layout: Layout,
    ...rest
  } = props;
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          Layout ? (
            <Layout location={props.location}>
              <Component {...rest} />
            </Layout>
          ) : (
            <Component {...rest} />
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export default connect(state => ({
  isAuthenticated: state.account.isAuthenticated,
}))(PrivateRouteContainer);
