import React from 'react';
import styled from 'styled-components';
import * as styling from '~/consts/styling';

const Text = ({ input, placeholder, meta: { touched, error }, ...custom }) => {
  const isInvalid = touched && error;
  return (
    <>
      <Input
        isInvalid={isInvalid}
        placeholder={placeholder}
        {...input}
        {...custom}
      />
      {isInvalid && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};

export default Text;

export const Input = styled.input`
  padding: 14px;
  width: 100%;
  height: 50px;
  font-size: 18px;
  border: ${props =>
    props.isInvalid
      ? `1px solid ${styling.red}`
      : `1px solid ${styling.borderGray}`};
  border-radius: 6px;
  box-sizing: border-box;
`;

export const ErrorMessage = styled.span`
  color: ${styling.red};
`;
