import React from 'react';
import { Header, Brand, Logo, TitleLink, Nav, NavLink } from './styled';

export default () => (
  <Header>
    <Brand>
      <Logo src="/img/logo.png" />
      <TitleLink exact to="/">
        Professor's insides
      </TitleLink>
    </Brand>
    <Nav>
      <NavLink to="/tags">Tags</NavLink>
      <NavLink to="/diseases">Diseases</NavLink>
      <NavLink to="/sources">Sources</NavLink>
    </Nav>
  </Header>
);
