import styled from 'styled-components';

export const LoginContainer = styled.div`
  max-width: 940px;
  margin: 0 auto;
  padding: 4px;
`;

export const H1 = styled.h1`
  margin-bottom: 30px;
  text-align: center;
  font-size: 28px;
`;

export const FormGroup = styled.div`
  margin-bottom: 30px;
`;

export const Label = styled.label`
  margin: 10px 20px 5px 0;
  font-size: 14px;
  font-weight: 700;
`;

export const ErrorList = styled.ul`
  color: red;
`;

export const Error = styled.li``;
