import React from 'react';
// import ReactDOM from 'react-dom';
import Select, { components } from 'react-select';
import * as Colors from '~/consts/styling';

const customStyles = ({ noResults, containerHeight, tagHeight }) => ({
  container: () => ({
    position: 'relative',
    boxSizing: 'border-box',
    flexGrow: 1,
    minHeight: containerHeight ? '108px' : '',
    height: containerHeight ? `${containerHeight}px` : '60px',
    border: `1px solid ${noResults ? Colors.red : Colors.borderGray}`,
    borderRadius: '6px',
  }),
  control: () => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '0',
    height: '100%',
  }),
  valueContainer: () => ({
    display: 'flex',
    flexGrow: '1',
    flexWrap: 'wrap',
    padding: '0 5px',
    height: '100%',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
  placeholder: () => ({
    position: 'absolute',
    top: '0',
    left: '0',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '19px',
    height: '100%',
  }),
  input: () => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '15px',
    height: '100%',
    '& ~ div': {
      top: '60px',
      left: '-67px',
    },
  }),
  menu: () => ({
    position: 'absolute',
  }),
  menuList: () => ({
    position: 'relative',
    padding: '15px 0',
    width: '172px',
    maxHeight: '300px',
    color: '#151515',
    borderRadius: '8px',
    backgroundColor: '#fff',
    boxShadow: '0px 5px 8px rgba(0, 0, 0, 0.3)',
    overflow: 'auto',
    zIndex: '999',
    '&:after': {
      position: 'absolute',
      top: '-12px',
      left: '94px',
      width: '24px',
      height: '24px',
      background: '#fff',
      transform: 'rotate(45deg)',
      boxShadow: '-1px -1px 8px -5px rgba(0,0,0,1)',
      content: '" "',
    },
  }),
  option: () => ({
    padding: '3px 20px',
    height: '24px',
    fontSize: '18px',
    cursor: 'pointer',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&:first-of-type': {
      marginTop: '0',
    },
    ':hover': {
      backgroundColor: Colors.borderGray,
    },
  }),
  multiValue: () => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: '15px',
    height: tagHeight ? `${tagHeight}px` : '',
  }),
  multiValueLabel: () => ({
    fontSize: '18px',
    color: '#151515',
  }),
  multiValueRemove: () => ({
    marginTop: '1px',
    marginLeft: '3px',
    width: '14px',
    height: '14px',
    color: '#fff',
    lineHeight: '14px',
    backgroundColor: '#f05228',
    borderRadius: '50%',
    cursor: 'pointer',
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
});

const Input = props => {
  return (
    <div id="TagInput" style={{ position: 'relative' }}>
      <components.Input {...props} />
    </div>
  );
};

// const Menu = (props) => {
//   // Input will not be rendered without any search value
//   // so menuPortalTarget is useless
//   const target = document.getElementById('TagInput');
//   if (!target) {
//     return (
//       <components.Menu {...props} />
//     );
//   }
//   return ReactDOM.createPortal(
//     <components.Menu {...props} />,
//     target
//   );
// };

const _Select = props => (
  <Select
    styles={customStyles({
      noResults: props.noResults,
      height: props.height,
      containerHeight: props.containerHeight,
      tagHeight: props.tagHeight,
    })}
    components={{ Input }}
    {...props}
  />
);

const ReactSelect = ({ input, meta: { touched, error }, ...custom }) => {
  const isInvalid = touched && error;
  return <_Select isInvalid={isInvalid} {...input} {...custom} />;
};

export default ReactSelect;
