import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import AppRoute from './appRoute';
import { MainLayout, EmptyLayout } from '~/components/layouts';
// todo: make it lazy
import Diseases from '~/containers/diseases';
import DiseaseDetails from '~/containers/diseaseDetails';
import Sources from '~/containers/sources';
import Tags from '~/containers/tags';
import Login from '~/containers/login';
import Registration from '~/containers/registration';

const Routes = () => (
  <Switch>
    <Redirect exact from="/" to="/diseases" />
    <AppRoute exact path="/diseases" component={Diseases} layout={MainLayout} />
    <AppRoute
      path="/diseases/new"
      component={DiseaseDetails}
      layout={MainLayout}
    />
    <AppRoute
      path="/diseases/:id"
      component={DiseaseDetails}
      layout={MainLayout}
    />
    <AppRoute path="/sources" component={Sources} layout={MainLayout} />
    <AppRoute path="/tags" component={Tags} layout={MainLayout} />
    <AppRoute path="/login" component={Login} layout={EmptyLayout} isPublic />
    <AppRoute
      path="/sign-up"
      component={Registration}
      layout={EmptyLayout}
      isPublic
    />
  </Switch>
);

export default Routes;
