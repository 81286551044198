import * as R from 'ramda';
import { FETCH_DATA_SUCCESS } from '~/actions/data';
import {
  SET_FILTER_LETTER,
  CREATE_DISEASE,
  CREATE_DISEASE_SUCCESS,
  CREATE_DISEASE_FAIL,
  UPDATE_DISEASE,
  UPDATE_DISEASE_SUCCESS,
  UPDATE_DISEASE_FAIL,
  DELETE_DISEASE,
  DELETE_DISEASE_SUCCESS,
} from '~/actions/disease';

const defaultState = {
  dataIsLoaded: false,
  dataIsLoading: false,
  dataLoadingError: false,
  diseases: [],
  diseaseIsProcessing: false,
  filterLetter: null,
};

export default function disease(state = defaultState, action) {
  switch (action.type) {
    case FETCH_DATA_SUCCESS: {
      return {
        ...defaultState,
        dataIsLoaded: true,
        dataIsLoading: false,
        dataLoadingError: false,
        diseases: [...action.payload.tumors],
      };
    }
    case SET_FILTER_LETTER: {
      return {
        ...state,
        filterLetter: action.payload,
      };
    }
    case CREATE_DISEASE: {
      return {
        ...state,
        diseaseIsProcessing: true,
      };
    }
    case CREATE_DISEASE_SUCCESS: {
      const disease = { ...action.payload };
      const diseases = R.append(disease, state.diseases);
      return {
        ...state,
        diseases,
        diseaseIsProcessing: false,
      };
    }
    case CREATE_DISEASE_FAIL: {
      return {
        ...state,
        diseaseIsProcessing: false,
      };
    }
    case UPDATE_DISEASE: {
      return {
        ...state,
        diseaseIsProcessing: true,
      };
    }
    case UPDATE_DISEASE_SUCCESS: {
      const { updatedDisease, editedDate } = action.payload;
      const disease = {
        ...updatedDisease,
        editedDate,
      };
      let { diseases } = state;
      const diseaseIdx = R.findIndex(R.propEq('id', disease.id), diseases);
      diseases = R.update(diseaseIdx, disease, diseases);
      return {
        ...state,
        diseases,
        diseaseIsProcessing: false,
      };
    }
    case UPDATE_DISEASE_FAIL: {
      return {
        ...state,
        diseaseIsProcessing: false,
      };
    }
    case DELETE_DISEASE: {
      return {
        ...state,
        diseaseIsProcessing: true,
      };
    }
    case DELETE_DISEASE_SUCCESS: {
      let { diseases } = state;
      const diseaseIdx = R.findIndex(R.propEq('id', action.payload), diseases);
      diseases = R.remove(diseaseIdx, 1, diseases);
      return {
        ...state,
        diseases,
        diseaseIsProcessing: false,
      };
    }
    default:
      return state;
  }
}
