import {
  createDiseaseRequest,
  updateDiseaseRequest,
  deleteDiseaseRequest,
} from '~/requests/disease';

export const SET_FILTER_LETTER = '@disease/setFilterLetter';

export const setFilterLetter = letter => ({
  type: SET_FILTER_LETTER,
  payload: letter,
});

export const CREATE_DISEASE = '@disease/createDisease';
export const CREATE_DISEASE_SUCCESS = '@disease/createDiseaseSuccess';
export const CREATE_DISEASE_FAIL = '@disease/createDiseaseFail';

export const createDisease = disease => dispatch => {
  dispatch({ type: CREATE_DISEASE });
  return createDiseaseRequest(disease);
};
export const createDiseaseSuccess = disease => ({
  type: CREATE_DISEASE_SUCCESS,
  payload: disease,
});
export const createDiseaseFail = () => ({ type: CREATE_DISEASE_FAIL });

export const UPDATE_DISEASE = '@disease/updateDisease';
export const UPDATE_DISEASE_SUCCESS = '@disease/updateDiseaseSuccess';
export const UPDATE_DISEASE_FAIL = '@disease/updateDiseaseFail';

export const updateDisease = disease => dispatch => {
  dispatch({ type: UPDATE_DISEASE });
  return updateDiseaseRequest(disease);
};
export const updateDiseaseSuccess = (updatedDisease, editedDate) => ({
  type: UPDATE_DISEASE_SUCCESS,
  payload: { updatedDisease, editedDate },
});
export const updateDiseaseFail = () => ({ type: UPDATE_DISEASE_FAIL });

export const DELETE_DISEASE = '@disease/deleteDisease';
export const DELETE_DISEASE_SUCCESS = '@disease/deleteDiseaseSuccess';

export const deleteDisease = id => dispatch => {
  dispatch({ type: DELETE_DISEASE });
  return deleteDiseaseRequest(id);
};
export const deleteDiseaseSuccess = id => ({
  type: DELETE_DISEASE_SUCCESS,
  payload: id,
});
