import styled from 'styled-components';
import * as styling from '~/consts/styling';

const Button = styled.button`
  margin: 0;
  padding: 0;
  width: auto;
  color: inherit;
  font: inherit;
  line-height: normal;
  border: none;
  overflow: visible;
  background: transparent;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
`;

export default Button;

export const ActionButton = styled(Button)`
  width: 160px;
  height: 45px;
  font-size: 18px;
  color: #fff;
  background-color: ${styling.blue};
  border-radius: 6px;
`;

export const FlatButton = styled(Button)`
  align-self: center;
  padding: 0 10px;
  height: 45px;
  color: ${props => (props.kind === 'danger' ? styling.red : styling.blue)};
  border-radius: 6px;
  font-size: 18px;
  :hover {
    background-color: ${styling.tableHover};
  }
`;
