import styled from 'styled-components';
import * as styling from '~/consts/styling';

const NavItem = styled.div`
  font-size: 18px;
  border-bottom: ${props => (props.active ? `2px solid ${styling.blue}` : '')};
  margin-left: ${props => (props.marginLeft ? `${props.marginLeft}px` : '3px')};
  cursor: pointer;
  :first-of-type {
    margin-left: 0;
  }
`;

export default NavItem;
