import fetch from '~/utils/fetch';
import { SOURCE_URL } from '~/consts/urls';

export const createSourceRequest = name =>
  fetch(SOURCE_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name }),
  });

export const updateSourceRequest = (id, name) =>
  fetch(`${SOURCE_URL}/${id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name }),
  });

export const deleteSourceRequest = id =>
  fetch(`${SOURCE_URL}/${id}`, { method: 'DELETE' });
