import fetch from '~/utils/fetch';
import { DISEASE_URL } from '~/consts/urls';

export const createDiseaseRequest = disease =>
  fetch(DISEASE_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(disease),
  });

export const updateDiseaseRequest = disease =>
  fetch(`${DISEASE_URL}/${disease.id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(disease),
  });

export const deleteDiseaseRequest = id =>
  fetch(`${DISEASE_URL}/${id}`, { method: 'DELETE' });
