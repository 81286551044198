import React from 'react';
import { Form, Field } from 'react-final-form';
import { ActionButton } from '~/components/shared/button';
import { Text } from '~/components/ui';
import { Label, FormGroup, ErrorList, Error } from './styled';
import { required, isEmail, composeValidators } from '~/utils/validation';

const emailValidation = composeValidators(
  required('Email is required'),
  isEmail('Email is incorrect')
);
const passwordValidation = composeValidators(required('Password is required'));

const LoginForm = props => {
  const { onSubmit, errors } = props;

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <Label htmlFor="Email">Email</Label>
              <Field
                type="email"
                name="email"
                id="Email"
                component={Text}
                validate={emailValidation}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="Password">Password</Label>
              <Field
                type="password"
                name="password"
                id="Password"
                component={Text}
                validate={passwordValidation}
              />
            </FormGroup>
            {errors && errors.length > 0 && (
              <ErrorList>
                {errors.map((e, i) => (
                  <Error key={i}>{e}</Error>
                ))}
              </ErrorList>
            )}
            <FormGroup>
              <ActionButton type="submit">Login</ActionButton>
            </FormGroup>
          </form>
        );
      }}
    />
  );
};

export default LoginForm;
