import CONFIG from '~/utils/config';

const baseUrl = CONFIG.apiBaseUrl;

export const LOGIN_URL = `${baseUrl}/account/login`;
export const REGISTER_URL = `${baseUrl}/account/register`;
export const DATA_URL = `${baseUrl}/data`;
export const TAG_URL = `${baseUrl}/tag`;
export const SOURCE_URL = `${baseUrl}/source`;
export const DISEASE_URL = `${baseUrl}/disease`;
