import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchData, fetchDataSuccess, fetchDataFail } from '~/actions/data';
import { setFilterLetter } from '~/actions/disease';
import { getGrouppedFilteredDiseases } from '~/reducers';
import Diseases from '~/components/diseases';

const mapStateToProps = state => {
  const { dataIsLoaded, dataIsLoading, dataLoadingError } = state.data;
  const { diseases: allDiseases, filterLetter } = state.disease;
  const diseasesNumber = allDiseases.length;
  const diseases = getGrouppedFilteredDiseases(state, filterLetter);
  return {
    dataIsLoaded,
    dataIsLoading,
    dataLoadingError,
    diseases,
    diseasesNumber,
    filterLetter,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchData: () => {
    dispatch(fetchData())
      .then(data => dispatch(fetchDataSuccess(data)))
      .catch(() => dispatch(fetchDataFail()));
  },
  setFilterLetter: letter => dispatch(setFilterLetter(letter)),
  dropFilterLetter: () => dispatch(setFilterLetter()),
  openDiseasDetail: id => dispatch(push(`/diseases/${id}`)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Diseases);
