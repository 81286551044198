import styled from 'styled-components';
import * as styling from '~/consts/styling';
import Select from '~/components/shared/select';
import Textarea from '~/components/ui/textarea';
import Input from '~/components/shared/text';
import { FlatButton } from '~/components/shared/button';

export const TopControls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`;

export const ControlGroup = styled.div`
  display: flex;
  width: 50%;
  justify-content: ${props => (props.alignRight ? 'flex-end' : '')};
`;

export const ButtonContainer = styled.div`
  margin-right: 30px;
`;

export const Container = styled.div`
  margin: 65px auto 0;
  max-width: 1110px;
`;

export const Label = styled.label`
  margin: 10px 20px 5px 0;
  font-size: 14px;
  font-weight: 700;
  display: ${props => (props.inline ? '' : 'flex')};
`;

export const RadioLabel = styled.label`
  color: ${props => (props.disabled ? '#AEB6BF' : '')};
`;

export const FormGroup = styled.div`
  display: ${props => (props.inline ? 'flex' : '')};
  margin-bottom: 30px;
`;

export const ColHalf = styled.div`
  width: 50%;
  margin-left: 10px;
  :first-of-type {
    margin-left: 0;
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 50px;
  &:first-of-type {
    margin: 0;
  }
`;

export const NoteContainer = styled.div`
  margin-top: 80px;
`;

export const Note = styled(Textarea)`
  padding: 6px;
  width: 540px;
  height: 110px;
  font-size: 16px;
  border: 1px solid ${styling.borderGray};
  border-radius: 6px;
  box-sizing: border-box;
`;

export const SelectSource = styled(Select)`
  width: 585px;
`;

export const PageSourceContainer = styled.div`
  margin-left: 30px;
`;

export const PageSource = styled(Input)`
  width: 120px;
`;

export const AddSourceButton = styled(FlatButton)`
  align-self: flex-end;
  margin-left: 20px;
`;

export const SourceList = styled.div`
  margin-top: 35px;
  width: 800px;
`;

export const SourceRow = styled.div`
  display: flex;
  margin-top: 10px;
  :first-of-type {
    margin-top: 0;
  }
`;

export const SourceRowNumber = styled.div`
  display: flex;
  align-items: center;
  width: 25px;
  text-align: center;
  font-size: 18px;
`;

export const SourceRowTitle = styled.div`
  flex-shrink: 1;
  width: 600px;
`;

export const SourceRowPage = styled.div`
  margin-left: 30px;
  width: 150px;
  max-width: 150px;
`;

export const SourceRowButton = styled.div`
  margin-left: 10px;
  width: 40px;
  max-width: 40px;
`;
