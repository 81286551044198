import styled from 'styled-components';
import * as styling from '~/consts/styling';

export const Input = styled.input`
  padding: 14px;
  height: 50px;
  font-size: 18px;
  border: ${props =>
    props.isInvalid
      ? `1px solid ${styling.red}`
      : `1px solid ${styling.borderGray}`};
  border-radius: 6px;
  box-sizing: border-box;
`;

export default Input;
