import styled from 'styled-components';
import * as styling from '~/consts/styling';
import { ButtonLink } from '~/components/shared/link';
import NavItem from '~/components/navItem';

export const Container = styled.div`
  margin: 65px auto 0;
  max-width: 1110px;
`;

export const DiseasesNumber = styled.span`
  font-size: 20px;
`;

export const AddBtn = styled(ButtonLink)`
  margin-left: 30px;
`;

export const Filter = styled.div`
  display: flex;
  margin-top: 32px;
`;

export const Letter = styled(NavItem)`
  margin-left: 5px;
  text-transform: uppercase;
`;

export const DiseasesList = styled.div`
  margin-top: 50px;
  padding-bottom: 50px;
  max-width: 1110px;
`;

export const TableHeader = styled.div`
  display: flex;
  padding-left: 90px;
  font-weight: 700;
  line-height: 40px;
`;

export const Group = styled.div`
  display: flex;
  margin-top: 105px;
  :first-of-type {
    margin-top: 0;
  }
`;

export const GroupKey = styled.div`
  width: 90px;
  font-size: 20px;
  font-weight: 700;
  line-height: 50px;
  text-transform: uppercase;
`;

export const DiseasesGroup = styled.ul`
  margin: 0;
  padding: 0;
  flex-grow: 1;
  list-style-type: none;
`;

export const Disease = styled.li`
  display: flex;
  border-bottom: 1px solid ${styling.borderGray};
  line-height: 50px;
  cursor: pointer;
  :last-of-type {
    border-bottom: none;
  }
  :hover {
    background-color: ${styling.tableHover};
  }
`;

export const Name = styled.div`
  flex-grow: 1;
`;

export const DateUpdated = styled.div`
  padding: 0 16px;
  width: 95px;
`;
