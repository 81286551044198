import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { createTag, createTagSuccess, createTagFail } from '~/actions/data';
import ActionInput from '~/components/actionInput';

const mapStateToProps = state => {
  const { tagIsProcessing } = state.data;
  return {
    tagIsProcessing,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  createTag: (typeId, name, alias, cleanup) =>
    dispatch(createTag(typeId, name, alias))
      .then(id => {
        dispatch(createTagSuccess(id, typeId, name, alias));
        if (cleanup) {
          cleanup();
        }
        toastr.success('Success', `Tag ${name} has been added.`);
        const { onSuccess } = ownProps;
        if (onSuccess) {
          onSuccess({ id, typeId: parseInt(typeId, 10), name });
        }
      })
      .catch(error => {
        toastr.error('Fail', error);
        dispatch(createTagFail());
      }),
});

const TagCreate = ({ tagType, tagIsProcessing, createTag }) => (
  <ActionInput
    width={550}
    placeholder="New Tag"
    labelSecondary="Alias"
    placeholderSecondary="(Alias)"
    primaryActionLabel={tagIsProcessing ? '...' : 'Add'}
    primaryActionHandler={(name, alias, cleanup) =>
      tagIsProcessing ? null : createTag(tagType, name, alias, cleanup)
    }
  />
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagCreate);
